.layout {
  width: 100%;
  height: 100vh;
  background: #0D2D5E;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message {
  max-width: 340px;
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  gap: 4px;
}

.icon {
  width: 40px;
  height: 40px;
  margin-bottom: 8px;
  opacity: 0.9;
  margin-left: -4px;
}

.title {
  font-size: 1.5em;
  line-height: 1.4;
  font-weight: 700;
}

.text {
  font-size: 1em;
  line-height: 1.3;
  font-weight: 400;
}
