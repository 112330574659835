 /*new actual*/
 @font-face {
   font-family: 'PTRootUIWebVF';
   src: url('../fonts/PT-Root-UI_VF.woff') format('woff');
   font-weight: 500 800;
   font-style: normal;
 }
 /*******/

@font-face {
  font-family: 'PT Root UI';
  src: url('../fonts/PT Root UI_Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'PT Root UI';
  src: url('../fonts/PT Root UI_Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'PT Root UI';
  src: url('../fonts/PT Root UI_Medium.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'PT Root UI';
  src: url('../fonts/PT Root UI_Bold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
    font-family: 'PT Root UI';
    src: url('../fonts/PT Root UI_Bold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

html, body {
  font-family: 'PT Root UI', Arial, sans-serif;
  height: 100%;
  font-size: 14px;
  scroll-behavior: smooth;
}

:root {
  --spacing-s: 8px;
  --spacing-m: 16px;
  --spacing-l: 32px;
  --grey: #E3E3E3;
  --bg-body: #F6F6F6;

   /*Colors*/

   /*Snow white*/
    --main-white: #FFFFFF;

    --main-white-500: oklch(100% 0 0 / 80%);
    --main-white-500-rgba: rgba(255, 255, 255, 0.8);

    --main-white-400: oklch(100% 0 0 / 56%);
    --main-white-400-rgba: rgba(255, 255, 255, 0.56);

    --main-white-300: oklch(100% 0 0 / 16%);
    --main-white-300-rgba: rgba(255, 255, 255, 0.16);

    --main-white-200: oklch(100% 0 0 / 8%);
    --main-white-200-rgba: rgba(255, 255, 255, 0.08);

    --main-white-100: oklch(100% 0 0 / 4%);
    --main-white-100-rgba: rgba(255, 255, 255, 0.04);

    /*Space gray*/
    --main-gray: #222934;
    --main-gray-900: #3E4756;
    --main-gray-800: #2F3743;

    --main-gray-700: oklch(32.06% 0.04 254.68 / 80%);
    --main-gray-700-rgba: rgba(37, 52, 71, 0.8);

    --main-gray-600: oklch(32.06% 0.04 254.68 / 56%);
    --main-gray-600-rgba: rgba(37, 52, 71, 0.56);

    --main-gray-500: oklch(32.06% 0.04 254.68 / 40%);
    --main-gray-500-rgba: rgba(37, 52, 71, 0.4);

    --main-gray-400: oklch(32.06% 0.04 254.68 / 28%);
    --main-gray-400-rgba: rgba(37, 52, 71, 0.28);

    --main-gray-300: oklch(32.06% 0.04 254.68 / 16%);
    --main-gray-300-rgba: rgba(37, 52, 71, 0.16);

    --main-gray-200: oklch(32.06% 0.04 254.68 / 8%);
    --main-gray-200-rgba: rgba(37, 52, 71, 0.08);

    --main-gray-100: oklch(32.06% 0.04 254.68 / 4%);
    --main-gray-100-rgba: rgba(37, 52, 71, 0.04);

    /*Dark blue*/
    --main-dark-blue: #0D2D5E;

    --main-dark-blue-700: oklch(30.54% 0.095 259.07 / 80%);
    --main-dark-blue-700-rgba: rgba(13, 45, 94, 0.8);

    --main-dark-blue-600: oklch(30.54% 0.095 259.07 / 56%);
    --main-dark-blue-600-rgba: rgba(13, 45, 94, 0.56);

    --main-dark-blue-500: oklch(30.54% 0.095 259.07 / 40%);
    --main-dark-blue-500-rgba: rgba(13, 45, 94, 0.4);

    --main-dark-blue-400: oklch(30.54% 0.095 259.07 / 28%);
    --main-dark-blue-400-rgba: rgba(13, 45, 94, 0.28);

    --main-dark-blue-300: oklch(30.54% 0.095 259.07 / 16%);
    --main-dark-blue-300-rgba: rgba(13, 45, 94, 0.16);

    --main-dark-blue-200: oklch(30.54% 0.095 259.07 / 8%);
    --main-dark-blue-200-rgba: rgba(13, 45, 94, 0.08);

    --main-dark-blue-100: oklch(30.54% 0.095 259.07 / 4%);
    --main-dark-blue-100-rgba: rgba(13, 45, 94, 0.04);

    --main-dark-blue-50: #F6F7F9;

    /*Blue azure*/
    --main-blue: #007FFF;
    --main-blue-900: #0066CE;
    --main-blue-800: #0070E1;

    --main-blue-700: oklch(61.31% 0.22 256.3 / 80%);
    --main-blue-700-rgba: rgba(0, 127, 255, 0.8);

    --main-blue-600: oklch(61.31% 0.22 256.3 / 56%);
    --main-blue-600-rgba: rgba(0, 127, 255, 0.56);

    --main-blue-500: oklch(61.31% 0.22 256.3 / 40%);
    --main-blue-500-rgba: rgba(0, 127, 255, 0.4);

    --main-blue-400: oklch(61.31% 0.22 256.3 / 28%);
    --main-blue-400-rgba: rgba(0, 127, 255, 0.28);

    --main-blue-300: oklch(61.31% 0.22 256.3 / 16%);
    --main-blue-300-rgba: rgba(0, 127, 255, 0.16);

    --main-blue-200: oklch(61.31% 0.22 256.3 / 8%);
    --main-blue-200-rgba: rgba(0, 127, 255, 0.08);

    --main-blue-100: oklch(61.31% 0.22 256.3 / 4%);
    --main-blue-100-rgba: rgba(0, 127, 255, 0.04);

    /*Green Toad in love*/
    --main-green: #3CAA3C;
    --main-green-900: #2C942C;
    --main-green-800: #349D34;

    --main-green-700: oklch(65.21% 0.179 143.18 / 80%);
    --main-green-700-rgba: rgba(60, 170, 60, 0.8);

    --main-green-600: oklch(65.21% 0.179 143.18 / 56%);
    --main-green-600-rgba: rgba(60, 170, 60, 0.56);

    --main-green-500: oklch(65.21% 0.179 143.18 / 40%);
    --main-green-500-rgba: rgba(60, 170, 60, 0.4);

    --main-green-400: oklch(65.21% 0.179 143.18 / 28%);
    --main-green-400-rgba: rgba(60, 170, 60, 0.28);

    --main-green-300: oklch(65.21% 0.179 143.18 / 16%);
    --main-green-300-rgba: rgba(60, 170, 60, 0.16);

    --main-green-200: oklch(65.21% 0.179 143.18 / 8%);
    --main-green-200-rgba: rgba(60, 170, 60, 0.08);

    --main-green-100: oklch(65.21% 0.179 143.18 / 4%);
    --main-green-100-rgba: rgba(60, 170, 60, 0.04);

    /*Yellow The Sandman*/
    --main-yellow: #FFD86C;
    --main-yellow-1000: #997F35;
    --main-yellow-900: #E5BE50;
    --main-yellow-800: #F2C855;

    --main-yellow-700: oklch(89.46% 0.13 89.67 / 80%);
    --main-yellow-700-rgba: rgba(255, 216, 108, 0.8);

    --main-yellow-600: oklch(89.46% 0.13 89.67 / 56%);
    --main-yellow-600-rgba: rgba(255, 216, 108, 0.56);

    --main-yellow-500: oklch(83.33% 0.16 102.85 / 40%);
    --main-yellow-500-rgba: rgba(255, 216, 108, 0.4);

    --main-yellow-400: oklch(89.46% 0.13 89.67 / 28%);
    --main-yellow-400-rgba: rgba(255, 216, 108, 0.28);

    --main-yellow-300: oklch(89.46% 0.13 89.67 / 16%);
    --main-yellow-300-rgba: rgba(255, 216, 108, 0.16);

    --main-yellow-200: oklch(89.46% 0.13 89.67 / 8%);
    --main-yellow-200-rgba: rgba(255, 216, 108, 0.08);

    --main-yellow-100: oklch(89.46% 0.13 89.67 / 4%);
    --main-yellow-100-rgba: rgba(255, 216, 108, 0.04);

    /*Orange juice*/
    --main-orange: #FF8519;
    --main-orange-900: #E57817;
    --main-orange-800: #F27E18;

    --main-orange-700: oklch(74.01% 0.18 53.67 / 80%);
    --main-orange-700-rgba: rgba(255, 133, 25, 0.8);

    --main-orange-600: oklch(74.01% 0.18 53.67 / 56%);
    --main-orange-600-rgba: rgba(255, 133, 25, 0.56);

    --main-orange-500: oklch(74.01% 0.18 53.67 / 40%);
    --main-orange-500-rgba: rgba(255, 133, 25, 0.4);

    --main-orange-400: oklch(74.01% 0.18 53.67 / 28%);
    --main-orange-400-rgba: rgba(255, 133, 25, 0.28);

    --main-orange-300: oklch(74.01% 0.18 53.67 / 16%);
    --main-orange-300-rgba: rgba(255, 133, 25, 0.16);

    --main-orange-200: oklch(74.01% 0.18 53.67 / 8%);
    --main-orange-200-rgba: rgba(255, 133, 25, 0.08);

    --main-orange-100: oklch(74.01% 0.18 53.67 / 4%);
    --main-orange-100-rgba: rgba(255, 133, 25, 0.04);

    /*Red Mario*/
    --main-red: #EC3F39;
    --main-red-900: #D13833;
    --main-red-800: #DE3B36;

    --main-red-700: oklch(62.59% 0.21 27.26 / 80%);
    --main-red-700-rgba: rgba(236, 63, 57, 0.8);

    --main-red-600: oklch(62.59% 0.21 27.26 / 56%);
    --main-red-600-rgba: rgba(236, 63, 57, 0.56);

    --main-red-500: oklch(62.59% 0.21 27.26 / 40%);
    --main-red-500-rgba: rgba(236, 63, 57, 0.4);

    --main-red-400: oklch(62.59% 0.21 27.26 / 28%);
    --main-red-400-rgba: rgba(236, 63, 57, 0.28);

    --main-red-300: oklch(62.59% 0.21 27.26 / 16%);
    --main-red-300-rgba: rgba(236, 63, 57, 0.16);

    --main-red-200: oklch(62.59% 0.21 27.26 / 8%);
    --main-red-200-rgba: rgba(236, 63, 57, 0.08);

    --main-red-100: oklch(62.59% 0.21 27.26 / 4%);
    --main-red-100-rgba: rgba(236, 63, 57, 0.04);

  /*PUBLIC COLORS */

  /*Snow white*/
  --public-white: #FFFFFF;

  /*#222934*/
  --public-text-primary: #222934;

  /*#253447 84%*/
  --public-text-secondary: rgba(37, 52, 71, 0.84);

  /*#0D2D5E 56%*/
  --public-text-ghost: rgba(13, 45, 94, 0.56);

  /*#0D2D5E 28%*/
  --public-text-disable: rgba(13, 45, 94, 0.28);

  /*#FFFFFF*/
  --public-text-white: rgba(255, 255, 255, 1);

  /*#FFFFFF*/
  --public-bg-white: rgba(255, 255, 255, 1);

  /*#F6F7F9*/
  --public-bg-gray: rgba(246, 247, 249, 1);

  /*#FFFFFF 60%*/
  --public-bg-white-60: rgba(255, 255, 255, 0.6);

  /*#007FFF 16%*/
  --public-bg-element-blue: rgba(0, 127, 255, 0.16);

  /*#0D2D5E 4%*/
  --public-bg-element-gray: rgba(13, 45, 94, 0.04);

  /*#3B557C*/
  --public-bg-label-gray: rgba(59, 85, 124, 1);

  /*#0D2D5E 8%*/
  --public-bg-line: rgba(13, 45, 94, 0.08);

  /*#007FFF*/
  --public-button-blue: rgba(0, 127, 255, 1);

  /*#0070E1*/
  --public-button-blue-hover: rgba(0, 112, 225, 1);

  /*#0066CE*/
  --public-button-blue-click: rgba(0, 102, 206, 1);

  /*#222934*/
  --public-button-black: rgba(34, 41, 52, 1);

  /*#2F3743*/
  --public-button-black-hover: rgba(47, 55, 67, 1);

  /*#3E4756*/
  --public-button-black-click: rgba(62, 71, 86, 1);

  /*#007FFF 16%*/
  --public-button-secondary-blue-hover: rgba(0, 127, 255, 0.16);

  /*#007FFF 28%*/
  --public-button-secondary-blue-click: rgba(0, 127, 255, 0.28);

  /*#0D2D5E 4%*/
  --public-button-disable: rgba(13, 45, 94, 0.04);

  /*#007FFF*/
  --public-icon-color: rgba(0, 127, 255, 1);

  /*#0D2D5E 56%*/
  --public-icon-ghost: rgba(13, 45, 94, 0.56);

  /* public nav offset */
  --public-aside-offset: 0px;
}

@property --icon-size {
  syntax: "<length>";
  inherits: true;
  initial-value: 32px;
}

/* youtrack helpdesk form */
form.feedbackForm__e972,
.confirmation__dcc2 {
  margin: 5vh auto 0;
  background: white;
  border-radius: 16px;
}
